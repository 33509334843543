import React, { useState } from "react";
import { useSignOut, useAuthUser } from "react-auth-kit";
import { useNavigate, useLocation, Link } from "react-router-dom";

import PropTypes from "prop-types";

import { Layout, Menu, Avatar, App, Dropdown } from "antd";
import { HomeFilled, UserOutlined } from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import logo from "../public/Bangun_logo_no_bg.png";

import colors from "../style/color";

import {
  INVENTORY_UAM,
  MANAGE_CUSTOMER_UAM,
  MANAGE_ORDER_UAM,
  MANAGE_CATEGORY_UAM,
  MANAGE_REPRESENTATIVE_UAM,
  MANAGE_UNIT_UAM,
  MANAGE_VENDOR_CONTRACT_UAM,
  MANAGE_SERVICE_UAM,
  MANAGE_ARRIVAL_RECEIPT_UAM,
  MANAGE_TRUCK_UAM,
  MANAGE_SHIP_UAM,
  MANAGE_SHIP_VENDOR_UAM,
  MANAGE_DEPO_UAM,
  MANAGE_PORT_UAM,
  MANAGE_RECEIVER_UAM,
  MANAGE_USER_UAM,
  MANAGE_ROLE_UAM,
  MANAGE_PORT_WAYBILL_INSIDE_DOCK_UAM,
  MANAGE_PORT_WAYBILL_OUTSIDE_DOCK_UAM,
  MANAGE_PACKER_UAM,
  MANAGE_DRIVER_UAM,
  MANAGE_VOYAGE_UAM,
  MANAGE_PACKING_LIST_UAM,
  MANAGE_ORDER_BATCH_UAM,
  MANAGE_INSURANCE_VENDOR_UAM,
  MANAGE_ADDITIONAL_FEE_UAM,
  MANAGE_ADDITIONAL_FEE_TEMPLATE_UAM,
  MANAGE_EVENT_REPORT_UAM,
  INVOICE_UAM,
  MANAGE_PRICE_UAM,
} from "constant/uam";

const { Header, Content, Footer, Sider } = Layout;
const { secondary } = colors;

export default function DefaultLayout({ children }) {
  const signOut = useSignOut();
  const auth = useAuthUser();
  const userAuthData = auth();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const [collapsed, setCollapsed] = useState(false);

  const listUamUuid = JSON.parse(localStorage.getItem("uam_uuid"));

  const items = [
    {
      key: "/",
      icon: <HomeFilled />,
      label: <Link to="/">Home</Link>,
      url: "/",
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "user" })} />,
      label: "User",
      children: [
        {
          uamkey: MANAGE_USER_UAM?.VIEW_PAGE,
          key: "/manage/user",
          label: <Link to="/manage/user">Manage User</Link>,
          url: "/manage/user",
        },
        {
          uamkey: MANAGE_ROLE_UAM?.VIEW_PAGE,
          key: "/manage/role",
          label: <Link to="/manage/role">Manage Role</Link>,
          url: "/manage/role",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "user-tie" })} />,
      label: "Employee",
      children: [
        {
          uamkey: MANAGE_DRIVER_UAM?.VIEW_PAGE,
          key: "/manage/driver",
          label: <Link to="/manage/driver">Manage Driver</Link>,
          url: "/manage/driver",
        },
        {
          uamkey: MANAGE_PACKER_UAM?.VIEW_PAGE,
          key: "/manage/packer",
          label: <Link to="/manage/packer">Manage Packer</Link>,
          url: "/manage/packer",
        },
        {
          uamkey: MANAGE_PACKER_UAM?.VIEW_PAGE,
          key: "/manage/driver-payout",
          label: <Link to="/manage/driver-payout">Driver Payout</Link>,
          url: "/manage/driver-payout",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "users" })} />,
      label: "Customer",
      children: [
        {
          uamkey: MANAGE_CUSTOMER_UAM?.VIEW_PAGE,
          key: "/manage/customer",
          label: <Link to="/manage/customer">Manage Customer</Link>,
          url: "/manage/customer",
        },
        {
          uamkey: MANAGE_RECEIVER_UAM?.VIEW_PAGE,
          key: "/manage/receiver",
          label: <Link to="/manage/receiver">Manage Receiver</Link>,
          url: "/manage/receiver",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "ship" })} />,
      label: "Cruise",
      children: [
        {
          uamkey: MANAGE_SHIP_VENDOR_UAM?.VIEW_PAGE,
          key: "/manage/cruise-vendor",
          label: <Link to="/manage/cruise-vendor">Cruise Vendor</Link>,
          url: "/manage/cruise-vendor",
        },
        {
          uamkey: MANAGE_VENDOR_CONTRACT_UAM?.VIEW_PAGE,
          key: "/manage/contract",
          label: <Link to="/manage/contract">Manage Contract</Link>,
          url: "/manage/contract",
        },
        {
          uamkey: MANAGE_DEPO_UAM?.VIEW_PAGE,
          key: "/manage/depo",
          label: <Link to="/manage/depo">Manage Depo</Link>,
          url: "/manage/depo",
        },
        {
          uamkey: MANAGE_PORT_UAM?.VIEW_PAGE,
          key: "/manage/port",
          label: <Link to="/manage/port">Manage Port</Link>,
          url: "/manage/port",
        },
        {
          uamkey: MANAGE_SHIP_UAM?.VIEW_PAGE,
          key: "/manage/ship",
          label: <Link to="/manage/ship">Manage Ship</Link>,
          url: "/manage/ship",
        },
        {
          uamkey: MANAGE_VOYAGE_UAM?.VIEW_PAGE,
          key: "/manage/voyage",
          label: <Link to="/manage/voyage">Manage Voyage</Link>,
          url: "/manage/voyage",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "city" })} />,
      label: "Master",
      children: [
        {
          uamkey: MANAGE_TRUCK_UAM?.VIEW_PAGE,
          key: "/manage/truck",
          label: <Link to="/manage/truck">Manage Truck</Link>,
          url: "/manage/truck",
        },
        {
          uamkey: MANAGE_SERVICE_UAM?.VIEW_PAGE,
          key: "/manage/service",
          label: <Link to="/manage/service">Manage Service</Link>,
          url: "/manage/service",
        },
        {
          uamkey: MANAGE_CATEGORY_UAM?.VIEW_PAGE,
          key: "/manage/category",
          label: <Link to="/manage/category">Manage Category</Link>,
          url: "/manage/category",
        },
        {
          uamkey: MANAGE_UNIT_UAM?.VIEW_PAGE,
          key: "/manage/unit",
          label: <Link to="/manage/unit">Satuan Barang</Link>,
          url: "/manage/unit",
        },
        {
          uamkey: MANAGE_REPRESENTATIVE_UAM?.VIEW_PAGE,
          key: "/manage/representative",
          label: <Link to="/manage/representative">Manage Perwakilan</Link>,
          url: "/manage/representative",
        },
        {
          uamkey: MANAGE_INSURANCE_VENDOR_UAM?.VIEW_PAGE,
          key: "/manage/insurance-vendor",
          label: <Link to="/manage/insurance-vendor">Insurance Vendor</Link>,
          url: "/manage/insurance-vendor",
        },
        {
          uamkey: MANAGE_ADDITIONAL_FEE_UAM?.VIEW_PAGE,
          key: "/manage/invoice-additional-fee",
          label: <Link to="/manage/invoice-additional-fee">Additional Fee</Link>,
          url: "/manage/invoice-additional-fee",
        },
        {
          uamkey: MANAGE_ADDITIONAL_FEE_TEMPLATE_UAM?.VIEW_PAGE,
          key: "/manage/invoice-additional-fee-template",
          label: <Link to="/manage/invoice-additional-fee-template">Template Additional Fee</Link>,
          url: "/manage/invoice-additional-fee-template",
        },
        {
          uamkey: MANAGE_PRICE_UAM?.VIEW_PAGE,
          key: "/manage/price",
          label: <Link to="/manage/price">Price</Link>,
          url: "/manage/price",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "receipt" })} />,
      label: "Orders",
      children: [
        {
          uamkey: MANAGE_ORDER_UAM?.VIEW_PAGE,
          key: "/manage/orders",
          label: <Link to="/manage/orders">Manage Order</Link>,
          url: "/manage/orders",
        },
        {
          uamkey: MANAGE_ARRIVAL_RECEIPT_UAM?.VIEW_PAGE,
          key: "/manage/arrival-receipt",
          label: <Link to="/manage/arrival-receipt">Arrival Receipt</Link>,
          url: "/manage/arrival-receipt",
        },
        {
          uamkey: INVENTORY_UAM?.VIEW_PAGE,
          key: "/manage/inventory",
          label: <Link to="/manage/inventory">Inventory</Link>,
          url: "/manage/inventory",
        },
        {
          uamkey: MANAGE_ORDER_BATCH_UAM?.VIEW_PAGE,
          key: "/manage/order-batch",
          label: <Link to="/manage/order-batch">Order Batch</Link>,
          url: "/manage/order-batch",
        },
        {
          uamkey: MANAGE_PACKING_LIST_UAM?.VIEW_PAGE,
          key: "/manage/packing-list",
          label: <Link to="/manage/packing-list">Packing List</Link>,
          url: "/manage/packing-list",
        },
        {
          uamkey: MANAGE_EVENT_REPORT_UAM?.VIEW_PAGE,
          key: "/manage/event-report",
          label: <Link to="/manage/event-report">Event Report</Link>,
          url: "/manage/event-report",
        },
        {
          uamkey: INVOICE_UAM?.VIEW_PAGE,
          key: "/invoice",
          label: <Link to="/invoice">Invoice</Link>,
          url: "/invoice",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "warehouse" })} />,
      label: "Outside Dock",
      children: [
        {
          uamkey: MANAGE_PORT_WAYBILL_OUTSIDE_DOCK_UAM?.VIEW_PAGE,
          key: "/manage/outside-dock/port-waybill",
          label: <Link to="/manage/outside-dock/port-waybill">Port Waybill</Link>,
          url: "/manage/outside-dock/port-waybill",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={icon({ name: "anchor" })} />,
      label: "Inside Dock",
      children: [
        {
          uamkey: MANAGE_PORT_WAYBILL_INSIDE_DOCK_UAM?.VIEW_PAGE,
          key: "/manage/inside-dock/port-waybill",
          label: <Link to="/manage/inside-dock/port-waybill">Port Waybill</Link>,
          url: "/manage/inside-dock/port-waybill",
        },
      ],
    },
    {
      key: "/export-data",
      icon: <FontAwesomeIcon icon={icon({ name: "file-export" })} />,
      label: <Link to="/export-data">Export Data</Link>,
      url: "/export-data",
    },
  ];

  const filteredItemsMenu = items.filter((item) => {
    if (item.key === "/" || item.key === "/export-data") {
      return true;
    }

    // If the item has children, filter its children recursively
    if (item.children) {
      item.children = item.children.filter((child) => listUamUuid.includes(child.uamkey));
      // Return true if any children have been retained
      return item.children.length > 0;
    }
    // Return true if the item's key is included in the list of access UUIDs
    return listUamUuid.includes(item.uamkey);
  });

  return (
    <App>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            overflow: "auto",
            height: "calc(100vh - 48px)",
            backgroundColor: "#9DC08B",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            paddingBottom: "32px",
          }}
        >
          <div className="h-auto flex justify-center items-center py-2">
            <img src={logo} alt="logo" className="w-20 h-20" />
          </div>
          <Menu
            selectedKeys={[pathname]}
            mode="inline"
            items={filteredItemsMenu}
            onSelect={(menu) => {
              if (window.event && window.event.metaKey) {
                window.open(menu?.item?.props?.url ?? "/", "_blank");
              } else {
                navigate(menu?.item?.props?.url ?? "/");
              }
            }}
          />
        </Sider>
        <Layout
          style={{
            marginLeft: collapsed ? "80px" : "200px",
          }}
        >
          <Header
            className="flex justify-end p-0"
            style={{
              lineHeight: "0",
              backgroundColor: secondary,
              color: "white",
              height: "52px",
            }}
          >
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <div
                        className="tracking-wider"
                        onClick={() => {
                          navigate("/manage/profile");
                        }}
                      >
                        <FontAwesomeIcon icon={icon({ name: "id-card" })} className="mr-2" />
                        Profile
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className="tracking-wider"
                        onClick={() => {
                          navigate("/login");
                          signOut();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={icon({ name: "right-from-bracket" })}
                          className="mr-2"
                        />
                        Logout
                      </div>
                    ),
                  },
                ],
              }}
              placement="bottomLeft"
              className="mr-2 px-2 rounded-xl hover:cursor-pointer"
            >
              <div className="flex items-center pr-2">
                <Avatar size="large" icon={<UserOutlined />} />
                <div className="flex flex-col gap-4">
                  <div className="ml-2 tracking-tight font-mono">{userAuthData?.displayName}</div>
                  <div className="ml-2 tracking-wider">{userAuthData?.email}</div>
                </div>
              </div>
            </Dropdown>
          </Header>
          <Content
            style={{
              padding: "0 16px",
              overflow: "initial",
            }}
          >
            {children}
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            BSB Management Program ©2023 Created by MotMot & KenKen
          </Footer>
        </Layout>
      </Layout>
    </App>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
};
